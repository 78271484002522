.App {
  text-align: left;
  flex-direction: column;
  background-color: rgb(24, 24, 24);
  font: Monospace;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
  margin-left: 16px;
}

.App-header {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 80px;
}

.WelcomeMessage {
  font-size: 24px;
}

.Welcome {
  min-height: 0vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  color: white;
}

.WelcomeMeta {
  min-height: 0vh;
  display: flex;
  margin-left: 64px;
  flex-direction: row;
  align-items: left;
  justify-content: left;
  color: lightblue;
}

.WelcomeItem {
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: left;
}

.WelcomeValue {
  margin-left: 16px;
  min-height: 0vh;
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: left;
  color: white;
}

.Shell {
  min-height: 0vh;
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: left;
  color: white;
}

.Name{
  color: yellowgreen;
}

.Printout {
  color: white;
  margin-left: 16px;
  display: flex;
  flex-direction: row;
}

.Folder {
  color: rgb(199, 139, 255);
  margin-left: 16px;
  display: flex;
  flex-direction: row;
}

.File {
  color: white;
  margin-left: 16px;
  display: flex;
  flex-direction: row;
}
